import { Navigate, Route, Routes, useParams,useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Home from "../pages/Home";
import React from 'react'
import Servicios from "../pages/Servicios";
import "./styles.css"


function GeneralRoutes(){
  const location = useLocation();
  return (
    <TransitionGroup>
    <CSSTransition
      key={location.pathname}
      timeout={400}
      classNames="fade"
    >

        <Routes location={location}>
            <Route path="/" element={<Home />} />
            <Route path="/servicios" element={<Servicios />} />
        </Routes>
    </CSSTransition>
    </TransitionGroup>
  )
}

export default GeneralRoutes
