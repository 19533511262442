import React from 'react';
import Header from '../../components/Header';
import './Home.css'; // Asegúrate de crear este archivo CSS o agregar las clases en tu archivo principal de estilos
import Hero from '../../components/Hero';
import Services from '../../components/Services';
import AboutUs from '../../components/AboutUs';
import Footer from '../../components/Footer';
import Map from '../../components/Map';
const Home = () => {
  return (
    <div className='h-auto flex flex-col' >
    <div className="home-container ">
        <div className='w-screen flex justify-center py-4 h-auto'>
            <div className='w-full flex flex-col items-center'>

            <Header />
            <Hero />

        </div>    

        </div>
      
      {/* Aquí puedes añadir más contenido sobre la imagen de fondo si es necesario */}
      </div>
      
      <Services />
      <AboutUs />
      <Map />
      <Footer/>
    </div>
  );
}

export default Home;
