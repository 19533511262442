import React from 'react';
import "./transformacion.css";
const Transformacion = () => {
  return (
    <div className='text-left h-full pb-24 w-full md:w-2/3 px-4 md:px-0'>
      <div className='text-left w-full py-12 sm:w-full h-auto transformacion-container'>
        <div className="inline-block bg-yellow-500 text-blue-900 px-3 py-1 rounded-full text-sm font-semibold mb-4">
          NUESTROS SERVICIOS
        </div>
        <h1 className="text-2xl lg:text-5xl font-bold mb-4 transformacion-title">
          Transformación digital y <br/>el poder de la{' '}
          <span className="relative">
            <span className="z-10 relative">innovación</span>
            <span className="absolute bottom-0 left-0 w-full h-2 bg-yellow-500 z-0"></span>
          </span>
        </h1>
        <p className="text-base md:text-lg text-justify transformacion-paragraph">
          Desbloquea el poder de tus datos. Con un enfoque pionero en{' '}
          <span className="font-semibold">análisis de datos e inteligencia artificial</span> y
          convierte complejidades en claridad, impulsa tu empresa hacia{' '}
          <span className="font-semibold">
            la eficiencia, la innovación y el éxito sin precedentes.
          </span>
        </p>
      </div>
    </div>
  );
}

export default Transformacion;
